import React from 'react';

import { useQuery } from 'react-query';

import { fetchFromFirebase } from '../../hooks/fetchFromFirebase';

//images
import LocationPin from '../../assets/images/location_pin.png';
import ContactPin from '../../assets/images/contact_pin.png';
import WorkingHoursPin from '../../assets/images/working-hours.png';

const ContactInfo = ({ collection, id }) => {

    const { isLoading, isError, data, error } = useQuery(['Dealer Info', collection, id], () => fetchFromFirebase('dealerInfo', 'KD6FHhBRkM5ISPT8qTRZ'));
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (isError) {
      return <div>Error: {error.message}</div>;
    }

    return (
        <section id="contact-section">
            <div className="row padding-vert-120">
                <div className="col s12 m4 ta-center">
                    <img src={LocationPin} alt="Τοποθεσία" className="max-height-50"/>
                    <p className="blue fw-900">Που θα μας βρείτε</p>
                    <p>{data.dealerAddress}</p>
                    <p className='padding-vert-10'><a href={'https://www.google.gr/maps/place/' + data.dealerAddress} className="btn-blue btn-animation fs-14 ta-center" target="_blank">Βρείτε μας στο χάρτη</a></p>
                </div>
                <div className="col s12 m4 ta-center">
                    <img src={ContactPin} alt="Επικοινωνία" className="max-height-50"/>
                    <p className="blue fw-900">Επικοινωνήστε μαζί μας</p>
                    <p>Τηλ: <a href={"tel:+30" + data.dealerPhone} className="black">{data.dealerPhone}</a><br />Fax: {data.dealerFax}<br /><a href={"mailto:" + data.dealerEmail} className="black">{data.dealerEmail}</a></p>
                </div>
                <div className="col s12 m4 ta-center">
                    <img src={WorkingHoursPin} alt="Ωράριο" className="max-height-50"/>
                    <p className="blue fw-900">Ώρες λειτουργίας</p>
                    <table className="footer-table">
                        <tbody>
                            <tr>
                                <td>Δευτέρα</td>
                                <td>{data.dealerMonday}</td>
                            </tr>
                            <tr>
                                <td>Τρίτη</td>
                                <td>{data.dealerTuesday}</td>
                            </tr>
                            <tr>
                                <td>Τετάρτη</td>
                                <td>{data.dealerWednesday}</td>
                            </tr>
                            <tr>
                                <td>Πέμπτη</td>
                                <td>{data.dealerThursday}</td>
                            </tr>
                            <tr>
                                <td>Παρασκευή</td>
                                <td>{data.dealerFriday}</td>
                            </tr>
                            <tr>
                                <td>Σάββατο</td>
                                <td>{data.dealerSaturday}</td>
                            </tr>
                        </tbody>
                    </table> 
                </div>
            </div>
        </section>
    )
}

export default ContactInfo;