import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
 
export default function Models() {
 
  const { isLoading, isError, data, error, refetch } = useQuery(["ModelsForm"], async () =>
  
  await axios.get(`https://still-term-0a01.anayia.workers.dev/?https://hyundai.gr/wp-json/wp/v2/models/?per_page=99`, {
    auth: {
        username: 'proweb',
        password: 'Rv2K HlpK ltVR 0QMX wnkh kIj1'
      }
  })
    .then((res) => res.data)
  );
 
  if (isLoading) return "Loading...";
 
  if (error) return "An error has occurred: " + error.message;
 
  // console.log(data)

  return (
    <>
      <option value=""></option>
      {
        data?.filter((a => a.acf.active.includes('yes')))
        .sort((b, c) => b.acf.order - c.acf.order)
        .map(models =>
          <option key={models.id} value={models.title.rendered}>
            {models.title.rendered}
          </option>
        )
      }
    </>
  )
};