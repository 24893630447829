import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBEA1WKUK13P86jIGLMOPk2GHSzjTxqYw4",
  authDomain: "patra-auto-abbd3.firebaseapp.com",
  projectId: "patra-auto-abbd3",
  storageBucket: "patra-auto-abbd3.appspot.com",
  messagingSenderId: "873198199715",
  appId: "1:873198199715:web:d031323f8ce82f60037016",
  measurementId: "G-ZMQ8S0FT07"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const storage = firebase.storage();
export const database = firebase.database();
export const firestore = firebase.firestore();