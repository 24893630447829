import React from 'react';

// Components
import Header from '../components/header';
import HomeSlider from '../components/slider';
import InterestForm from '../components/forms/interestForm';
// import ProothitikiEnergeia from '../components/proothitikes';
import ServiceForm from '../components/forms/serviceForm';
import Configurator from '../components/configurator';
import Models from '../components/models';
import UsedCars from '../components/usedCars';
import Posts from '../components/posts';
import Footer from '../components/footer';

const App = () => {

    return (
            <>
                <Header />
                <HomeSlider />
                <InterestForm />
                {/* <ProothitikiEnergeia /> */}
                <ServiceForm />
                <Configurator />
                <Models />
                <UsedCars />
                <Posts />
                <Footer />
            </>
        )
}

export default App;