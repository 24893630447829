import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
 
import SingleModel from './singleModel';
 
export default function Models() {
 
  const { isLoading, isError, data, error, refetch } = useQuery(["Models"], async () =>
  
  await axios.get(`https://still-term-0a01.anayia.workers.dev/?https://hyundai.gr/wp-json/wp/v2/models/?per_page=99`, {
    auth: {
        username: 'proweb',
        password: 'Rv2K HlpK ltVR 0QMX wnkh kIj1'
      }
  })
    .then((res) => res.data)
  );
 
  if (isLoading) return "Loading...";
 
  if (error) return "An error has occurred: " + error.message;
 
  // console.log(data)

  return (
    <>
        <div id="model-section" className='container padding-vert-80'>
            <h3 className='fw-900 ta-center'>Τα μοντέλα μας</h3>
            <div className='row'>
                {
                    data?.filter((a => a.acf.active.includes('yes')))
                    .sort((b, c) => b.acf.order - c.acf.order)
                    .map(models =>
                        <div key={models.id} className='col m4 s12 ta-center relative'>
                            <SingleModel 
                                name={models.title.rendered}
                                imageId={models.acf.image_side}
                                pageId={models.acf.pip_page.url}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    </>
  )
};