import React, { useState, useEffect } from "react";
import { useQuery } from 'react-query';
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { auth, storage } from "../../../firebase";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UsedCars () {

    const id = 'KD6FHhBRkM5ISPT8qTRZ';

    const [buttonTitle, setButtonTitle] = useState("");
    const [buttonURL, setButtonURL] = useState("");

    useEffect(() => {
        const fetchData = async () => {
          const docRef = firebase.firestore().collection("usedCars").doc(id);
          const doc = await docRef.get();
          
          if (doc.exists) {
            const data = doc.data();
            setButtonTitle(data.ButtonTitle);
            setButtonURL(data.ButtonUrl);
          } else {
            console.log("Document not found");
          }
        };
        
        fetchData();
    }, [id]);

    // Updating data in Firestore
    const handleSliderInfoSubmit = (event) => {
        event.preventDefault();
        
        const data = {
            ButtonTitle: buttonTitle,
            ButtonUrl: buttonURL
        };

        const toastSubmission = toast.loading("Παρακαλώ περιμένετε...", { position: toast.POSITION.BOTTOM_RIGHT});
        
        firebase.firestore().collection("usedCars").doc(id).update(data).then(() => {
            // alert("Document updated successfully");
            toast.update(toastSubmission, { render: "Επιτυχής ενημέρωση", type: "success", autoClose: 5000, hideProgressBar: false, isLoading: false, position: toast.POSITION.BOTTOM_RIGHT });
        }).catch((error) => {
            alert("Error updating document: " + error.message);
        });
    };

    return (
        <div className='container equal-heights-container padding-vert-40'>
            <div className="title-container fs-3vw fw-900 blue vertical">
                Used Cars
            </div>
            <div className="content-container border-left-blue padding-left-20">
                <div  className='dashboard-form'>
                    <form onSubmit={handleSliderInfoSubmit}>
                        <div className="row">
                            <div className="col s12">
                        <p className='form-label blue'>Button Title</p>
                        <input type="text" value={buttonTitle} onChange={(event) => setButtonTitle(event.target.value)} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12">
                        <p className='form-label blue'>Button Url<br /><i>(αν αφορά τηλέφωνο εισάγετε tel:+30XXXXXXXXXX)</i></p>
                        <input type="text" value={buttonURL} onChange={(event) => setButtonURL(event.target.value)} />
                            </div>
                        </div>

                        <div className="row">
                            <div className='col s12 ta-center padding-vert-10'>
                                <button type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer autoClose={5000} />
            </div>
        </div>
      );
    }
export default UsedCars;
