import React, { useState, useEffect } from "react";
import { useQuery } from 'react-query';
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { auth, storage } from "../../../firebase";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Slider({ imageName }) {

    const id = 'KD6FHhBRkM5ISPT8qTRZ';

    const [sliderTitle, setSliderTitle] = useState("");
    const [sliderSubTitle, setSliderSubTitle] = useState("");

    useEffect(() => {
        const fetchData = async () => {
          const docRef = firebase.firestore().collection("slider").doc(id);
          const doc = await docRef.get();
          
          if (doc.exists) {
            const data = doc.data();
            setSliderTitle(data.title);
            setSliderSubTitle(data.subtitle);
          } else {
            console.log("Document not found");
          }
        };
        
        fetchData();
    }, [id]);

    // Updating data in Firestore
    const handleSliderInfoSubmit = (event) => {
        event.preventDefault();
        
        const data = {
            title: sliderTitle,
            subtitle: sliderSubTitle
        };

        const toastSubmission = toast.loading("Παρακαλώ περιμένετε...", { position: toast.POSITION.BOTTOM_RIGHT});
        
        firebase.firestore().collection("slider").doc(id).update(data).then(() => {
            // alert("Document updated successfully");
            toast.update(toastSubmission, { render: "Επιτυχής ενημέρωση", type: "success", autoClose: 5000, hideProgressBar: false, isLoading: false, position: toast.POSITION.BOTTOM_RIGHT });
        }).catch((error) => {
            alert("Error updating document: " + error.message);
        });
    };

    // Fetch stored slider image
    const { data: imageUrl, refetch } = useQuery(
        ['image', imageName],
        async () => {
          const storageRef = storage.ref(`files/slider`);
          const url = await storageRef.getDownloadURL();
          return url;
        },
        { enabled: true } // disable automatic fetching
    );

    // Upload new slider image
    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);

    const handleSliderImageSubmit = (e) => {
        e.preventDefault()
        const file = e.target[0]?.files[0]
        if (!file) return;

        const storageRef = storage.ref(`files/slider`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setImgUrl(downloadURL);
                // Refetch slider image on success
                refetch();
                });
            }
        );
    }

  return (
    <div className='container equal-heights-container padding-vert-40'>
        <div className="title-container fs-3vw fw-900 blue vertical">
            Slider
        </div>
        <div className="content-container border-left-blue padding-left-20">
            <div className="row">
                <div className="col s12">
                    {imageUrl && (
                        <img src={imageUrl} alt={`Image ${imageName}`} className='max-width-100' />
                    )}
                    {
                        !imgUrl &&
                        <div className='outerbar'>
                        <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
                        </div>
                    }
                </div>
            </div>

            <div  className='dashboard-form'>

                <form onSubmit={handleSliderImageSubmit}>
                    <div className="row">
                        <div className="col s12">
                            <input type='file' accept='image/png, image/jpeg'/>
                            <div className='col s12 ta-center padding-vert-10'>
                                <button type='submit'>Upload</button>
                            </div>
                        </div>
                    </div>
                </form>

            
                <form onSubmit={handleSliderInfoSubmit}>
                    <div className="row">
                        <div className="col s12">
                            <p className='form-label blue'>Slider Title</p>
                            <input type="text" value={sliderTitle} onChange={(event) => setSliderTitle(event.target.value)} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s12">
                            <p className='form-label blue'>Slider Subtitle</p>
                            <input type="text" value={sliderSubTitle} onChange={(event) => setSliderSubTitle(event.target.value)} />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col s12 ta-center padding-vert-10'>
                            <button type="submit">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer autoClose={5000} />
        </div>
    </div>
  );
}
export default Slider;
