import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
 
export default function FooterMenu() {
 
  const { isLoading, isError, data, error, refetch } = useQuery(["Footer Menu"], async () =>
  
  await axios.get(`https://still-term-0a01.anayia.workers.dev/?https://hyundai.gr/wp-json/proweb/menus/footer-bottom-menu`, {
    auth: {
        username: 'proweb',
        password: 'Rv2K HlpK ltVR 0QMX wnkh kIj1'
      }
  })
    .then((res) => res.data)
  );
 
  if (isLoading) return "Loading...";
 
  if (error) return "An error has occurred: " + error.message;
 
  // console.log(data)

  return (
    <div className="row footer-menu-container">
        <div className="col s12 ta-right fs-11">
            <div className="footer-bottom-menu">
                <ul id="menu-footer-bottom-menu" className="menu">
                    {
                        data?.map(menu =>
                            <li key={menu.ID}><a href={menu.url} className='no-outline' target="_blank" rel="noreferrer" >{menu.title}</a></li>
                        )
                    }
                </ul>
            </div>
        </div>
    </div>
  )
};