import React from 'react';

import configuratorBgImg from '../../assets/images/configurator.jpeg';

const Configurator = () => {
    return (
        <div id="configurator-section" className="slider overflow-hidden">
            <div className='slides relative'>
                <div className='relative'>
                    <div className="slider-bg relative" style={{ backgroundImage: `url(${configuratorBgImg})` }}></div>
                    <div className="slider-container relative" style={{ backgroundImage: `url(${configuratorBgImg})` }}>
                        <div className="slider-equal-heights-container white-color relative z-99">
                            <div className="slider-container-title width-75 white relative">
                                <h1 className="slider-title drop-shadow">Διαμορφωτής Hyundai.</h1>
                                <h3 className="slider-subtitle drop-shadow">Σχεδιάστε το ιδανικό αυτοκίνητο σύμφωνα με τις ανάγκες σας.</h3>
                            </div>
                            <div className="slider-container-button ta-center width-25 relative">
                                <a href="https://configurator.hyundai.gr/" className="btn-blue btn-animation width-75 fs-14 ta-center float-right" target="_blank" rel="noreferrer">Περισσότερα</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div> 
    )
}

export default Configurator;