import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { getFirestore, collection, addDoc, getDocs } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import { auth, db } from "../../firebase";
import AuthHeader from "../../components/dashboard/authHeader";

// const fetchUsers = async () => {
//   const querySnapshot = await getDocs(collection(db, 'users'));
//   return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
// };

// const UsersList = ({ users, refetchUsers }) => {
//   return (
//     <ul>
//       {users.map((user) => (
//         <li key={user.id}>{user.name} ({user.email})</li>
//       ))}
//     </ul>
//   );
// };

// const CreateUserForm = ({ refetchUsers }) => {
//   const [formData, setFormData] = useState({ email: '', password: '' });
//   const [error, setError] = useState(null);

//   const createUser = async () => {
//     try {
//       // Create the user with email and password
//       const { user } = await createUserWithEmailAndPassword(auth, formData.email, formData.password);

//       // Save the user data to Firestore
//       const docRef = await addDoc(collection(db, 'users'), { email: formData.email });
//       setFormData({ email: '', password: '' });

//       // Call the refetchUsers function passed as a prop to update the users list
//       refetchUsers();
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   const handleFormSubmit = (event) => {
//     event.preventDefault();
//     createUser();
//   };

//   const handleInputChange = (event) => {
//     setFormData({ ...formData, [event.target.name]: event.target.value });
//   };

//   return (
//     <div>
//       <form onSubmit={handleFormSubmit}>
//         <label htmlFor="email">Email:</label>
//         <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} />
//         <label htmlFor="password">Password:</label>
//         <input type="password" id="password" name="password" value={formData.password} onChange={handleInputChange} />
//         <button type="submit">Submit</button>
//       </form>
//       {error && <p>Error creating user: {error}</p>}
//     </div>
//   );
// };

const Users = () => {
  // const { isLoading, error, data: users, refetch } = useQuery('users', fetchUsers);

  // if (isLoading) return <p>Loading...</p>;

  // if (error) return <p>Error: {error.message}</p>;

  return (
    <>
        {/* <AuthHeader />
        <UsersList users={users} refetchUsers={refetch} />
        <CreateUserForm refetchUsers={refetch} /> */}
    </>
  );
};

export default Users;
