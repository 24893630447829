import React, {Component} from 'react';

import ContactInfo from './contactInfo';
import FirstColumn from './firstColumn';
import SecondColumn from './secondColumn';
import ThirdColumn from './thirdColumn';
import FourthColumn from './fourthColumn';
import FifthColumn from './fifthColumn';
import SocialMedia from './socialMedia';
import FooterMenu from './footerMenu';

import WhiteLogo from '../../assets/images/Hyundai-Logo-white.png';

class Footer extends Component{

    render() {
        return (
            <>
                <ContactInfo />
                <footer>
                    <div className='container padding-vert-120'>
                        <div className="row footer-columns padding-vert-40 footer-border-bottom">
                            <div className="col s12 m12 l2">
                                <a href="https://www.hyundai.gr/" className="brand-logo"><img src={WhiteLogo} alt="Hyundai"/></a>
                            </div>
                            <FirstColumn />
                            <SecondColumn />
                            <ThirdColumn />
                            <FourthColumn />
                            <FifthColumn />
                        </div>
                        <SocialMedia />
                        <FooterMenu />
                        <div className="col s12 fs-11">
                            Ⓒ Copyright {new Date().getFullYear()} Hyundai ΕΛΛΑΣ ΑΒΕΕ. Created by <a href="https://www.proweb.gr/" className="underline white no-outline" target="_blank">PROWEB</a>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
    
}

export default Footer;