import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

function AuthHeader() {

    const [loggedInUser, loading, error] = useAuthState(auth);
    // const [firstName, setFirstName] = useState("");
    // const [lastName, setLastName] = useState("");
    const navigate = useNavigate();

    const handleLogout = async () => {
      try {
        await auth.signOut();
        // User is logged out, you can redirect or show some feedback
      } catch (error) {
        // Handle logout error
      }
    };
  
    // const fetchUserName = async () => {
    //   try {
    //     const q = query(collection(db, "users"), where("uid", "==", loggedInUser?.uid));
    //     const doc = await getDocs(q);
    //     const data = doc.docs[0].data();
    //     // console.log(data);
    //     setFirstName(data?.firstName);
    //     setLastName(data?.lastName);
    //   } catch (err) {
    //     console.error(err);
    //     alert("An error occured while fetching user data");
    //   }
    // };
  
    useEffect(() => {
      if (loading) return;
      if (!loggedInUser) return navigate("/");
      // fetchUserName();
    }, [loggedInUser, loading]);
  
    return (
        <div className="block fs-12 ta-right white blue-bg">
          <div className="inline-block">
            {/* Συνδεθήκατε ως {firstName} {lastName} ({user?.email}) -  */}
            Συνδεθήκατε ως {loggedInUser?.email} - 
            </div>
            <ul className="auth-nav inline-block">
              <li className="inline-block pointer padding-hor-10" onClick={() => navigate("/")}>
                Home
              </li>
              <li className="inline-block pointer padding-hor-10" onClick={() => navigate("/dashboard")}>
                Dashboard
              </li>
              {/* <li className="inline-block pointer padding-hor-10" onClick={() => navigate("/users")}>
                Χρήστες
              </li> */}
              <li className="inline-block pointer padding-hor-10" onClick={handleLogout}>
                Αποσύνδεση
              </li>
            </ul>
        </div>
    );
  }
  
  export default AuthHeader;