import React from 'react';

// SVG
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/svg/instagram.svg';
import { ReactComponent as Youtube } from '../../assets/svg/youtube.svg';
import { ReactComponent as Twitter } from '../../assets/svg/twitter.svg';
import { ReactComponent as Linkedin } from '../../assets/svg/linkedin.svg';

const ContactInfo = () => {
    return (
        <div className="row social-media-container padding-vert-40 footer-border-bottom social-media-container">
            <div className="col s12">
                <a href="https://www.facebook.com/HyundaiGreece/" target="_blank" rel="noreferrer"><Facebook /></a>
                <a href="https://www.instagram.com/hyundai.gr/" target="_blank" rel="noreferrer"><Instagram /></a>
                <a href="https://www.youtube.com/user/HyundaiGreece" target="_blank" rel="noreferrer"><Youtube /></a>
                <a href="https://twitter.com/hyundaiGR?fbclid=IwAR2lxFtXHtFFtjaiNhLD8wrtwYy4jtCfKeZU3PDZy3HHwe3ag9bcpOlnxNU" target="_blank" rel="noreferrer"><Twitter /></a>
                <a href="https://www.linkedin.com/company/hyundai-hellas/" target="_blank" rel="noreferrer"><Linkedin /></a>
            </div>
        </div>
    )
}

export default ContactInfo;