import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

//images
import Logo from '../assets/images/hyundai_logo.png';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
       
    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate("/dashboard");
            // console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
       
    }
 
    return (
        <div className="abs-center width-25">
            <div className="ta-center">
                <img src={Logo} alt="Hyundai"/>
            </div>
            <div className='login-form'>
                <div className='col m6 s12 padding-vert-10'>
                    <p className='form-label blue'>Email</p>
                    <input
                        id="email-address"
                        name="email"
                        type="email"                                    
                        required
                        onChange={(e)=>setEmail(e.target.value)}
                    />
                </div>

                <div className='col m6 s12 padding-vert-10'>
                    <p className='form-label blue'>Password</p>
                    <input
                        id="password"
                        name="password"
                        type="password"                                    
                        required                                                                                
                        onChange={(e)=>setPassword(e.target.value)}
                    />
                </div>

                <div className='col s12 ta-center padding-vert-10'>
                    <button onClick={onLogin}>  
                        Σύνδεση
                    </button>
                </div>

            </div>
        </div>
    )
}
 
export default Login