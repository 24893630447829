import React from "react";

import AuthHeader from "../../components/dashboard/authHeader";
import DealerInfo from "../../components/dashboard/dealerInfo";
import Slider from '../../components/dashboard/slider';
import UsedCars from "../../components/dashboard/usedCars";

function Dashboard() {

  return (
    <>
      <AuthHeader />
      <DealerInfo />
      <Slider />
      <UsedCars />
    </>
  );
}

export default Dashboard;