import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
 
export default function FirstColumn() {
 
  const { isLoading, isError, data, error, refetch } = useQuery(["Footer First Column"], async () =>
  
  await axios.get(`https://still-term-0a01.anayia.workers.dev/?https://hyundai.gr/wp-json/wp/v2/models`, {
    auth: {
        username: 'proweb',
        password: 'Rv2K HlpK ltVR 0QMX wnkh kIj1'
      }
  })
    .then((res) => res.data)
  );
 
  if (isLoading) return "Loading...";
 
  if (error) return "An error has occurred: " + error.message;
 
  // console.log(data)

  return (
    <div className='col s12 m12 l2 footer--column--menu'>
        <div className="footer-border-bottom heading">
            <a>Μοντέλα</a>
        </div>
        <div className='footer-column-menu'>
            <ul className='margin-0'>
                {
                    data?.filter((a => a.acf.active.includes('yes')))
                    .sort((b, c) => b.acf.order - c.acf.order)
                    .map(models =>
                        <li key={models.id}><a href={models.acf.pip_page.url} className='footer-menu-item no-outline' target="_blank" rel="noreferrer">{models.title.rendered}</a></li>
                    )
                }
            </ul>
        </div>
    </div>
  )
};