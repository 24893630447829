import React, { useState, useEffect } from "react";
import { useQuery } from 'react-query';
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { auth, storage } from "../../../firebase";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DealerInfo ({ logoName }) {

    const id = 'KD6FHhBRkM5ISPT8qTRZ';

    const [dealerName, setDealerName] = useState("");
    const [dealerAddress, setDealerAddress] = useState("");
    const [dealerEmail, setDealerEmail] = useState("");
    const [dealerPhone, setDealerPhone] = useState("");
    const [dealerFax, setDealerFax] = useState("");
    const [dealerMonday, setDealerMonday] = useState("");
    const [dealerTuesday, setDealerTuesday] = useState("");
    const [dealerWednesday, setDealerWednesday] = useState("");
    const [dealerThursday, setDealerThursday] = useState("");
    const [dealerFriday, setDealerFriday] = useState("");
    const [dealerSaturday, setDealerSaturday] = useState("");

    useEffect(() => {
        const fetchData = async () => {
          const docRef = firebase.firestore().collection("dealerInfo").doc(id);
          const doc = await docRef.get();
          
          if (doc.exists) {
            const data = doc.data();
            setDealerName(data.dealerName);
            setDealerAddress(data.dealerAddress);
            setDealerEmail(data.dealerEmail);
            setDealerPhone(data.dealerPhone);
            setDealerFax(data.dealerFax);
            setDealerMonday(data.dealerMonday);
            setDealerTuesday(data.dealerTuesday);
            setDealerWednesday(data.dealerWednesday);
            setDealerThursday(data.dealerThursday);
            setDealerFriday(data.dealerFriday);
            setDealerSaturday(data.dealerSaturday);
          } else {
            console.log("Document not found");
          }
        };
        
        fetchData();
    }, [id]);

    // Updating data in Firestore
    const handleSliderInfoSubmit = (event) => {
        event.preventDefault();
        
        const data = {
            dealerName: dealerName,
            dealerAddress: dealerAddress,
            dealerEmail: dealerEmail,
            dealerPhone: dealerPhone,
            dealerFax: dealerFax,
            dealerMonday: dealerMonday,
            dealerTuesday: dealerTuesday,
            dealerWednesday: dealerWednesday,
            dealerThursday: dealerThursday,
            dealerFriday: dealerFriday,
            dealerSaturday: dealerSaturday
        };

        const toastSubmission = toast.loading("Παρακαλώ περιμένετε...", { position: toast.POSITION.BOTTOM_RIGHT});
        
        firebase.firestore().collection("dealerInfo").doc(id).update(data).then(() => {
            // alert("Document updated successfully");
            toast.update(toastSubmission, { render: "Επιτυχής ενημέρωση", type: "success", autoClose: 5000, hideProgressBar: false, isLoading: false, position: toast.POSITION.BOTTOM_RIGHT });
        }).catch((error) => {
            alert("Error updating document: " + error.message);
        });
    };

    // Fetch stored slider image
    const { data: logoImageUrl, refetch } = useQuery(
        ['logo', logoName],
        async () => {
          const storageRef = storage.ref(`files/logo`);
          const url = await storageRef.getDownloadURL();
          return url;
        },
        { enabled: true } // disable automatic fetching
    );

    // Upload new slider image
    const [logoUrl, setLogoUrl] = useState(null);
    const [progressLogoPercent, setProgressLogoPercent] = useState(0);

    const handleLogoSubmit = (e) => {
        e.preventDefault()
        const file = e.target[0]?.files[0]
        if (!file) return;

        const storageRef = storage.ref(`files/logo`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progressLogo =
                Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgressLogoPercent(progressLogo);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setLogoUrl(downloadURL);
                // Refetch slider image on success
                refetch();
                });
            }
        );
    }

    return (
        <div className='container equal-heights-container padding-vert-40'>
            <div className="title-container fs-3vw fw-900 blue vertical">
                Dealer Info
            </div>
            <div className="content-container border-left-blue padding-left-20">
            <div className="row">
                    <div className="col s12">
                        {logoImageUrl && (
                            <img src={logoImageUrl} alt={`Image ${logoName}`} className='max-width-100' />
                        )}
                        {
                            !logoUrl &&
                            <div className='outerbar'>
                            <div className='innerbar' style={{ width: `${progressLogoPercent}%` }}>{progressLogoPercent}%</div>
                            </div>
                        }
                    </div>
                </div>
                <div  className='dashboard-form'>

                <form onSubmit={handleLogoSubmit}>
                    <div className="row">
                        <div className="col s12">
                            <input type='file' accept='image/png, image/jpeg'/>
                            <div className='col s12 ta-center padding-vert-10'>
                                <button type='submit'>Upload</button>
                            </div>
                        </div>
                    </div>
                </form>

                    <form onSubmit={handleSliderInfoSubmit}>
                        <div className="row">
                            <div className="col s12">
                                <p className='form-label blue'>Oνομασία Dealer</p>
                                <input type="text" value={dealerName} onChange={(event) => setDealerName(event.target.value)} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12">
                                <p className='form-label blue'>Διεύθυνση Dealer</p>
                                <input type="text" value={dealerAddress} onChange={(event) => setDealerAddress(event.target.value)} />
                            </div>
                        </div>
    
                        <div className="row">
                            <div className="col m4 s12">
                                <p className='form-label blue'>Email Dealer</p>
                                <input type="text" value={dealerEmail} onChange={(event) => setDealerEmail(event.target.value)} />
                            </div>
                            <div className="col m4 s12">
                                <p className='form-label blue'>Τηλέφωνο Dealer</p>
                                <input type="text" value={dealerPhone} onChange={(event) => setDealerPhone(event.target.value)} />
                            </div>
                            <div className="col m4 s12">
                                <p className='form-label blue'>Fax Dealer</p>
                                <input type="text" value={dealerFax} onChange={(event) => setDealerFax(event.target.value)} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col m4 s12">
                                <p className='form-label blue'>Ωράριο Δευτέρας</p>
                                <input type="text" value={dealerMonday} onChange={(event) => setDealerMonday(event.target.value)} />
                            </div>
                            <div className="col m4 s12">
                                <p className='form-label blue'>Ωράριο Τρίτης</p>
                                <input type="text" value={dealerTuesday} onChange={(event) => setDealerTuesday(event.target.value)} />
                            </div>
                            <div className="col m4 s12">
                                <p className='form-label blue'>Ωράριο Τετάρτης</p>
                                <input type="text" value={dealerWednesday} onChange={(event) => setDealerWednesday(event.target.value)} />
                            </div>
                            <div className="col m4 s12">
                                <p className='form-label blue'>Ωράριο Πέμπτης</p>
                                <input type="text" value={dealerThursday} onChange={(event) => setDealerThursday(event.target.value)} />
                            </div>
                            <div className="col m4 s12">
                                <p className='form-label blue'>Ωράριο Παρασκευής</p>
                                <input type="text" value={dealerFriday} onChange={(event) => setDealerFriday(event.target.value)} />
                            </div>
                            <div className="col m4 s12">
                                <p className='form-label blue'>Ωράριο Σαββάτου</p>
                                <input type="text" value={dealerSaturday} onChange={(event) => setDealerSaturday(event.target.value)} />
                            </div>

                        </div>

                        <div className="row">
                            <div className='col s12 ta-center padding-vert-10'>
                                <button type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer autoClose={5000} />
            </div>
        </div>
      );
    }
export default DealerInfo;
