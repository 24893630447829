import React, { Component } from 'react';
import axios from 'axios'; 

import ActiveModels from './activeModels';

class InterestForm extends Component {
  state = {
    interest: '',
    lastName: '',
    firstName: '',
    phone: '',
    email: '',
    remarks: '',
  };

handleSubmit = event => {
    event.preventDefault();
    axios.post('https://hyundai-training.onedealer.com/Files/Services/ODInquiryServices.onesrv?m=CreateInquiryInterestByCase', {
        inquiry : {
            Interest: this.state.interest,
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Email: this.state.email, 
            MobilePhone: this.state.phone,
            notes: this.state.remarks,
            BranchCode: 'IpsMzteQfEKMGaFMoqJWgw', 
            InboundKey: 'O4KZWmO5F0yV_xazc3lki',
        }
    })
      .then(res=>{
        // console.log(res);
        // console.log(res.data);
        // window.location = '/retrieve' //This line of code will redirect you once the submission is succeed
      })
  }

  handleInterestChange = (event) => {
    this.setState({interest: event.target.value});
    // console.log(this.state);
  }
  handleLastNameChange = (event) => {
    this.setState({lastName: event.target.value});
    // console.log(this.state);
  }
  handleFirstNameChange = (event) => {
    this.setState({firstName: event.target.value});
    // console.log(this.state);
  }
  handlePhoneChange = (event) => {
    this.setState({phone: event.target.value});
    // console.log(this.state);
  }
  handleEmailChange = (event) => {
    this.setState({email: event.target.value});
    // console.log(this.state);
  }
  handleRemarksChange = (event) => {
    this.setState({remarks: event.target.value});
    // console.log(this.state);
  }

render() {
    return (
        <div id="dealer-section" className='interest-section blue-bg padding-vert-80'>
            <div className='container'>
                <div className='row'>
                    <div className='col m6 s12'>
                        <div className='fs-3vw lh-3vw fw-900 white'>Ενδιαφέρεστε να αποκτήσετε ένα Hyundai;</div>
                        <div className='white margin-top-20 margin-bottom-40'>Γνωρίστε και οδηγήστε όλα τα μοντέλα της Hyundai</div>
                    </div>
                    <div className='col m6 s12'>
                        <div className='row interest-form white-bg'>
                            <form onSubmit = { this.handleSubmit }>
                                <div className='col m6 s12 padding-vert-10'>
                                    <p className='form-label blue'>Επώνυμο</p>
                                    <input
                                        type='text'
                                        name='lastName'
                                        onChange={this.handleLastNameChange}
                                    />
                                </div>
                                <div className='col m6 s12 padding-vert-10'>
                                    <p className='form-label blue'>Όνομα</p>
                                    <input
                                        type='text'
                                        name='firstName'
                                        onChange={this.handleFirstNameChange}
                                    />
                                </div>
                                <div className='col m6 s12 padding-vert-10'>
                                    <p className='form-label blue'>Κινητό Τηλέφωνο</p>
                                    <input
                                        type='number'
                                        name='phone'
                                        onChange={this.handlePhoneChange}
                                    />
                                </div>
                                <div className='col m6 s12 padding-vert-10'>
                                    <p className='form-label blue'>Email</p>
                                    <input
                                        type='email'
                                        name='email'
                                        onChange={this.handleEmailChange}
                                    />
                                </div>
                                <div className='col s12 padding-vert-10'>
                                    <p className='form-label blue'>Ενδιαφέρομαι για το μοντέλο</p>
                                    <select
                                        name='interest'
                                        value={this.state.interest}
                                        onChange={this.handleInterestChange}
                                    >
                                        <ActiveModels />
                                    </select>
                                </div>
                                <div className='col s12 padding-vert-10'>
                                    <p className='form-label blue'>Σχόλια</p>
                                    <textarea 
                                        rows='4'
                                        name='remarks'
                                        onChange={this.handleRemarksChange}
                                    />
                                </div>
                                <div className='col s12 ta-center padding-vert-10'>
                                    <button
                                        type='submit'
                                    >
                                        Υποβολή
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default InterestForm;