import React from 'react';
import { useQuery } from 'react-query';

import { fetchFromFirebase } from '../../hooks/fetchFromFirebase';

import usedCarsBgImg from '../../assets/images/hyundai_test_drive.jpg';

 // Fetch Slider Dealer Info
 const UsedCars = ({ collection, id }) => {
    const { isLoading, isError, data, error } = useQuery(['Dealer Used Cars', collection, id], () => fetchFromFirebase('usedCars', 'KD6FHhBRkM5ISPT8qTRZ'));
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (isError) {
      return <div>Error: {error.message}</div>;
    }
  
    return (
        <div id="used-cars-section" className="slider overflow-hidden">
            <div className='slides relative'>
                <div className='relative'>
                    <div className="slider-bg relative" style={{ backgroundImage: `url(${usedCarsBgImg})` }}></div>
                    <div className="slider-container relative" style={{ backgroundImage: `url(${usedCarsBgImg})` }}>
                        <div className="slider-equal-heights-container white-color relative z-99">
                            <div className="slider-container-title width-75 white relative">
                                <h1 className="slider-title drop-shadow">Επιλεγμένα Μεταχειρισμένα Hyundai</h1>
                                <h3 className="slider-subtitle drop-shadow">Επικοινωνήστε μαζί μας και αποκτήστε επιλεγμένα μεταχειρισμένα Hyundai με προνομιακούς όρους.</h3>
                            </div>
                            <div className="slider-container-button ta-center width-25 relative">
                                <a href={data.ButtonUrl} className="btn-blue btn-animation width-75 fs-14 ta-center float-right" rel="noreferrer">{data.ButtonTitle}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div> 
    );
};

// const UsedCars = () => {
//     return (
//         <div id="used-cars-section" className="slider overflow-hidden">
//             <div className='slides relative'>
//                 <div className='relative'>
//                     <div className="slider-bg relative" style={{ backgroundImage: `url(${usedCarsBgImg})` }}></div>
//                     <div className="slider-container relative" style={{ backgroundImage: `url(${usedCarsBgImg})` }}>
//                         <div className="slider-equal-heights-container white-color relative z-99">
//                             <div className="slider-container-title width-75 white relative">
//                                 <h1 className="slider-title drop-shadow">Επιλεγμένα Μεταχειρισμένα Hyundai</h1>
//                                 <h3 className="slider-subtitle drop-shadow">Επικοινωνήστε μαζί μας και αποκτήστε επιλεγμένα μεταχειρισμένα Hyundai με προνομιακούς όρους.</h3>
//                             </div>
//                             <div className="slider-container-button ta-center width-25 relative">
//                                 <a href={"tel:+30" + dealerPhone} className="btn-blue btn-animation width-75 fs-14 ta-center float-right" rel="noreferrer">Καλέστε μας!</a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>   
//         </div> 
//     )
// }

export default UsedCars;