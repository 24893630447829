import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';

// Components
import Home from '../pages/home';
import Login from '../pages/login';
import Dashboard from '../pages/dashboard';
import Users from '../pages/dashboard/users';

const queryClient = new QueryClient();

const App = () => {

    // Firebase Auth
    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                //   console.log("uid", uid)
            } else {
                // User is signed out
                //   console.log("user is logged out")
            }
          });
         
    }, [])

    return (
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                
                <BrowserRouter>
                    <Routes>
                        <Route index element={<Home />} />
                        <Route path="login" element={<Login />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="users" element={<Users />} />
                    </Routes>
                </BrowserRouter>

            </QueryClientProvider>
        )
}

export default App;