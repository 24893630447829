import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export const fetchFromFirebase = async (collection, id) => {
    const db = firebase.firestore();
    const docRef = db.collection(collection).doc(id);
    const doc = await docRef.get();
    if (doc.exists) {
      return { id: doc.id, ...doc.data() };
    } else {
      throw new Error(`Document with id '${id}' not found in collection '${collection}'.`);
    }
};