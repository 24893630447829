import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
 
export default function Posts() {
 
  const { isLoading, isError, data, error, refetch } = useQuery(["posts"], async () =>
  
  await axios.get(`https://still-term-0a01.anayia.workers.dev/?https://hyundai.gr/wp-json/wp/v2/posts/?_embed`, {
    auth: {
        username: 'proweb',
        password: 'Rv2K HlpK ltVR 0QMX wnkh kIj1'
      }
  })
    .then((res) => res.data)
  );
 
  if (isLoading) return "Loading...";
 
  if (error) return "An error has occurred: " + error.message;
 
  // console.log(data)

  return (
    <>
        <div id="posts-section" className='container padding-vert-80'>
            <h3 className='fw-900 ta-center'>Τα νέα μας</h3>
            <div className='row'>
                {
                    data?.slice(0, 3).map(posts =>
                        <div key={posts.id} className='col m4 s12 relative'>
                            <img
                                className='post-img'
                                src={posts._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url}
                                alt={posts.title.rendered}
                            />
                            <a href={posts.link} target="_blank" rel="noreferrer"><h5 className='fw-900 black'>{posts.title.rendered}</h5></a>
                            <div dangerouslySetInnerHTML={{__html: posts.excerpt.rendered}} />
                        </div>
                    )
                }
            </div>
        </div>
    </>
  )
};