import React, { useRef } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import Slider from 'react-slick';

import { fetchFromFirebase } from '../../hooks/fetchFromFirebase';
import { storage } from "../../firebase";
import SliderImg from '../dashboard/slider/sliderImg';
 
export default function HomeSlider({ imageName }) {

    // Fetch Slider Dealer Image
    const { data: imageUrl } = useQuery(
        ['image', imageName],
        async () => {
          const storageRef = storage.ref(`files/slider`);
          const url = await storageRef.getDownloadURL();
          return url;
        },
        { enabled: true } // disable automatic fetching
    );

    const customSlider = useRef();

    // Fetch Slider Dealer Info
    const SliderInfoItem = ({ collection, id }) => {
        const { isLoading, isError, data, error } = useQuery(['Dealer Slider', collection, id], () => fetchFromFirebase(collection, id));
      
        if (isLoading) {
          return <div>Loading...</div>;
        }
      
        if (isError) {
          return <div>Error: {error.message}</div>;
        }
      
        return (
          <div>
            <h1 className='slider-title drop-shadow'>{data.title}</h1>
            <h3 className='slider-subtitle drop-shadow'>{data.subtitle}</h3>
            {/* ... */}
          </div>
        );
    };

    // Fetch Sliders from hyundai.gr
    const { isLoading, isError, data, error, refetch } = useQuery(["slider"], async () =>

    await axios.get(`https://still-term-0a01.anayia.workers.dev/?https://hyundai2020.proweb.gr/wp-json/acf/v3/blocks?page=4808`, {
        auth: {
            username: 'proweb',
            password: 'uxQm S9uJ fCnm PyID 3oC7 9PhU'
        }
    })
    .then((res) => res.data[0].slides)

    );
    
    if (isLoading) return "Loading...";

    if (error) return "An error has occurred: " + error.message;

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: false
    };

    // console.log(data);

  return (
    <>
        <link
            rel='stylesheet'
            type='text/css'
            charSet='UTF-8'
            href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
        />
        <link
            rel='stylesheet'
            type='text/css'
            href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
        />
        <div className='slider'>
            <div className='slides relative'>
                <Slider ref={slider => (customSlider.current = slider)} {...settings}>
                    <div key='dealer-1' className='relative'>
                        <div className='slider-bg relative' style={{ backgroundImage: `url(${imageUrl})` }}></div>
                        <div className='slider-container relative' style={{ backgroundImage: `url(${imageUrl})` }}>
                            <div className='slider-equal-heights-container white-color relative z-99'>
                                <div className='slider-container-title width-100 white relative'>
                                    <SliderInfoItem collection="slider" id="KD6FHhBRkM5ISPT8qTRZ" />
                                </div>
                            </div>
                            <div className='slider--arrow--container'>
                                <div className='tp-leftarrow tparrows pointer slick-arrow' onClick={() => customSlider.current.slickPrev()}></div>
                                <div className='tp-rightarrow tparrows pointer slick-arrow' onClick={() => customSlider.current.slickNext()}></div>
                            </div>
                        </div>
                    </div>
                    {
                        data?.slice(0, 3)
                        .map((slides,index) =>
                            <div className='relative' key={index}>
                                <div className='slider-bg relative' style={{ backgroundImage: `url(${slides.slider_image})` }}></div>
                                <div className='slider-container relative' style={{ backgroundImage: `url(${slides.slider_image})` }}>
                                    <div className='slider-equal-heights-container white-color relative z-99'>
                                        <div className='slider-container-title width-75 white relative'>
                                            <div dangerouslySetInnerHTML={{__html: slides.slider_info}} />
                                        </div>
                                        <div className='slider-container-button ta-center width-25 relative'>
                                            <a href={slides.slider_link.url} className='btn-blue btn-animation width-75 fs-14 ta-center float-right' target='_blank' rel='noreferrer'>Περισσότερα</a>
                                        </div>
                                    </div>
                                    <div className='slider--arrow--container'>
                                        <div className='tp-leftarrow tparrows pointer slick-arrow' onClick={() => customSlider.current.slickPrev()}></div>
                                        <div className='tp-rightarrow tparrows pointer slick-arrow' onClick={() => customSlider.current.slickNext()}></div>
                                    </div>
                                </div>
                            </div>
                        )
                    }                
                    
                </Slider>
                <div className='slides-navigation relative'>
                    <div className='container relative'></div>
                </div>
            </div>
        </div>
    </>
  )
};