import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
 
export default function Posts(props) {
 
  const name = props.name;
  const { isLoading, isError, data, error, refetch } = useQuery([{name}], async () =>
  
  await axios.get(`https://still-term-0a01.anayia.workers.dev/?https://hyundai.gr/wp-json/wp/v2/media/${props.imageId}`, {
    auth: {
        username: 'proweb',
        password: 'Rv2K HlpK ltVR 0QMX wnkh kIj1'
      }
  })
    .then((res) => res.data)
  );
 
  if (isLoading) return "Loading...";
 
  if (error) return "An error has occurred: " + error.message;
 
//   console.log(data)

  return (
    <>
        <a href={props.pageId} className='black' target='_blank'  rel='noreferrer'>
            <img style={{width: '100%', height:'100%'}} src={data.source_url} alt={props.name} />
            <p className='black'>{props.name}</p>
        </a>
    </>
  )
};