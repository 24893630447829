import React, { Component } from 'react';
import emailjs from '@emailjs/browser';

class ServiceForm extends Component {

    state = {
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
        model: '',
        licensePlate: '',
        remarks: ''
      }

      handleSubmit(e) {
        e.preventDefault()
        const { lastName, firstName, phone, email, model, licensePlate, remarks } = this.state
        let templateParams = {
          from_name: email,
          to_name: 'yiannis@proweb.gr',
          subject: 'Service',
          lastName: lastName,
          firstName: firstName,
          phone: phone,
          model: model,
          licensePlate: licensePlate,
          remarks: remarks
         }
         emailjs.send(
          'service_78z1qy9',
          'template_ii6dpay',
           templateParams,
          'nbcJGybNjvwMoGoOO'
         )
         this.resetForm()
     }

    //  handleSubmit = (e) => {
    //     e.preventDefault();
    
    //     emailjs.sendForm('service_78z1qy9', 'template_ii6dpay', this.form.current, 'nbcJGybNjvwMoGoOO')
    //       .then((result) => {
    //           console.log(result.text);
    //       }, (error) => {
    //           console.log(error.text);
    //       });
    //       this.resetForm();
    //   };

    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
    }

    resetForm() {
        this.setState({
            name: '',
            email: '',
            subject: '',
            message: '',
        })
    }

render() {
    return (
        <div id="service-section" className='service-section light-blue-bg padding-vert-80'>
            <div className='container'>
                <div className='row'>
                    <div className='col m6 s12'>
                        <div className='fs-3vw lh-3vw fw-900 white'>Εξουσιοδοτημένο Service</div>
                        <div className='white margin-top-20 margin-bottom-40'>Ζητήστε σήμερα διαθεσιμότητα για ραντεβού service στο Hyundai σας και θα επικοινωνήσουμε μαζί σας.</div>
                    </div>
                    <div className='col m6 s12'>
                        <div className='row service-form'>
                            <form onSubmit={this.handleSubmit.bind(this)}>
                                <div className='col m6 s12 padding-vert-10'>
                                    <p className='form-label white'>Επώνυμο</p>
                                    <input
                                        type='text'
                                        name='lastName'
                                        value={this.state.lastName}
                                        onChange={this.handleChange.bind(this, 'lastName')}
                                        required
                                    />
                                </div>
                                <div className='col m6 s12 padding-vert-10'>
                                    <p className='form-label white'>Όνομα</p>
                                    <input
                                        type='text'
                                        name='firstName'
                                        value={this.state.firstName}
                                        onChange={this.handleChange.bind(this, 'firstName')}
                                        required
                                    />
                                </div>
                                <div className='col m6 s12 padding-vert-10'>
                                    <p className='form-label white'>Κινητό Τηλέφωνο</p>
                                    <input
                                        type='number'
                                        name='phone'
                                        value={this.state.phone}
                                        onChange={this.handleChange.bind(this, 'phone')}
                                        required
                                    />
                                </div>
                                <div className='col m6 s12 padding-vert-10'>
                                    <p className='form-label white'>Email</p>
                                    <input
                                        type='email'
                                        name='email'
                                        value={this.state.email}
                                        onChange={this.handleChange.bind(this, 'email')}
                                        required
                                    />
                                </div>
                                <div className='col m6 s12 padding-vert-10'>
                                    <p className='form-label white'>Μοντέλο</p>
                                    <input
                                        type='text'
                                        name='model'
                                        value={this.state.model}
                                        onChange={this.handleChange.bind(this, 'model')}
                                        required
                                    />
                                </div>
                                <div className='col m6 s12 padding-vert-10'>
                                    <p className='form-label white'>Αριθμός πινακίδας</p>
                                    <input
                                        type='text'
                                        name='licensePlate'
                                        value={this.state.licensePlate}
                                        onChange={this.handleChange.bind(this, 'licensePlate')}
                                        required
                                    />
                                </div>
                                <div className='col s12 padding-vert-10'>
                                    <p className='form-label white'>Σχόλια</p>
                                    <textarea 
                                        rows='4'
                                        name='remarks'
                                        value={this.state.remarks}
                                        onChange={this.handleChange.bind(this, 'remarks')}
                                    />
                                </div>
                                <div className='col s12 ta-center padding-vert-10'>
                                    <button
                                        type='submit'
                                    >
                                        Υποβολή
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default ServiceForm;