import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { storage } from "../../firebase";

import AuthHeader from '../dashboard/authHeader';
import { dealerName } from '../../dealerInfo';

//images
import Logo from '../../assets/images/hyundai_logo.png';
import DealerLogo from '../../assets/images/patra_logo.png';

function Header({ logoName }) {

    const [user] = useAuthState(auth);

    // Fetch stored slider image
    const { data: logoImageUrl, refetch } = useQuery(
        ['logo', logoName],
        async () => {
          const storageRef = storage.ref(`files/logo`);
          const url = await storageRef.getDownloadURL();
          return url;
        },
        { enabled: true } // disable automatic fetching
    );

    return (
        <div className="nav-container">
            {user ? <AuthHeader /> : ''}
            <nav className="container">
                <div className="nav-wrapper">
                    <a href="https://patra-auto.hyundai.gr/" className="brand-logo" rel="home">
                        <img src={Logo} alt="Hyundai"/>
                        {logoImageUrl && (
                            <img src={logoImageUrl} alt={`Image ${logoName}`} className='mobile max-width-150px' />
                        )}
                    </a>
                    <ul className="float-right hide-on-med-and-down">
                        <li className="menu-item"><a href="true" rel="noopener" className="blue" onClick = {(e)=>{e.preventDefault(); window.scrollTo({top: document.querySelector("#dealer-section").offsetTop, behavior: "smooth"});}}>Υπηρεσίες</a></li>
                        <li className="menu-item"><a href="true" rel="noopener" className="blue"  onClick = {(e)=>{e.preventDefault(); window.scrollTo({top: document.querySelector("#service-section").offsetTop, behavior: "smooth"});}}>Service</a></li>
                        <li className="menu-item"><a href="true" rel="noopener" className="blue" onClick = {(e)=>{e.preventDefault(); window.scrollTo({top: document.querySelector("#configurator-section").offsetTop, behavior: "smooth"});}}>Διαμορφωτής</a></li>
                        <li className="menu-item"><a href="true" rel="noopener" className="blue" onClick = {(e)=>{e.preventDefault(); window.scrollTo({top: document.querySelector("#model-section").offsetTop, behavior: "smooth"});}}>Μοντέλα</a></li>
                        <li className="menu-item"><a href="true" rel="noopener" className="blue" onClick = {(e)=>{e.preventDefault(); window.scrollTo({top: document.querySelector("#used-cars-section").offsetTop, behavior: "smooth"});}}>Μεταχειρισμένα</a></li>
                        <li className="menu-item"><a href="true" rel="noopener" className="blue" onClick = {(e)=>{e.preventDefault(); window.scrollTo({top: document.querySelector("#posts-section").offsetTop, behavior: "smooth"});}}>Νέα</a></li>
                        <li className="menu-item"><a href="true" rel="noopener" className="blue" onClick = {(e)=>{e.preventDefault(); window.scrollTo({top: document.querySelector("#contact-section").offsetTop, behavior: "smooth"});}}>Επικοινωνία</a></li>
                        {logoImageUrl && (
                            <img src={logoImageUrl} alt={`Image ${logoName}`} className='va-middle max-width-150px' />
                        )}
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Header;